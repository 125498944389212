.header-link {
  background-color: transparent;
  opacity: 0.8;
  font-weight: 500;

  &:focus {
    background-color: transparent !important;
  }
  
  &:active,
  &.active-border {
    border-bottom: 3px solid var(--header-active-border-color) !important;
    opacity: 1;
  }

  .dark-label {
    color: var(--header-background-color);
  }
}
