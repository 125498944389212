.expired-tag {
  background-color: #ff720c26;
  color: #ff720c;
}

.pending-tag {
  background-color: #6e798c26;
  color: #6e798c;
}

.open-tag {
  background-color: #1890ff26;
  color: #1890ff;
}

.complete-tag {
  background-color: #b3cb2d33;
  color: #a5bb2b;
}

.rejected-tag {
  background-color: #ff472e26;
  color: #ff472e;
}
