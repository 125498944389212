.logo {
  width: 172px;
  height: 46px;
  background-size: contain;

  &.has-error {
    margin-bottom: 20px;
  }

  &:global(.logo-pacific) {
    background: url('../../assets/images/pacific-logo.png') no-repeat;
  }

  &:global(.logo-pacific-white) {
    background: url('../../assets/images/pacific-logo-white.png') no-repeat;
  }
}
