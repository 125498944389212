.table-wrapper {
  min-height: 210px;
  max-height: calc(100vh - 17rem);
}

.product-colummn {
  min-width: 18rem;
}

@media screen and (max-width: 600px) {
  .product-colummn {
    min-width: 12rem;
  }
}

.pcf-requests-table :global(.p-datatable-tbody > tr) {
  height: 62px;
}

.pcf-requests-table :global(.p-datatable-tbody > tr > td) {
  padding: 1.25rem 1rem;
}
