.auth-bg {
  background: linear-gradient(300deg, rgba(243, 149, 0, 1) 40%, rgba(249, 188, 71, 1) 100%);

  .auth-container {
    @media (max-width: 600px) {
      width: 100%;
    }

    @media (min-width: 1200px) {
      width: 1206px;
    }
  }

  .info-block {
    gap: 30px;
    background-color: rgba(227, 244, 255, 0.5);
    @media (min-width: 1200px) {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }

  .auth-block {
    @media (min-width: 1200px) {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    @media (max-width: 600px) {
      border-radius: 0;
    }
  }

  /* Centering CircularTree's Circle logo */
  .circulartree-logo {
    margin-right: -30px;
  }
}
