.section-info {
  .section-icon {
    min-width: 46px;

    .info-icon {
      display: block;
      width: 46px;
      height: 46px;

      &.first {
        background: url('../../../../assets/images/login_image_1.svg') no-repeat;
        background-position: center;
        background-color: var(--primary-color);
        border-radius: 12px;
      }

      &.second {
        background: url('../../../../assets/images/login_image_2.svg') no-repeat;
        background-position: center;
        background-color: var(--primary-color);
        border-radius: 12px;
      }

      &.third {
        background: url('../../../../assets/images/login_image_3.svg') no-repeat;
        background-position: center;
        background-color: var(--primary-color);
        border-radius: 12px;
      }
    }
  }

  .info-header-text {
    color: black;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 6px;
    letter-spacing: -0.25px;
  }
}
