.new-notification-dot {
  width: 8px;
  height: 8px;
}

.notification-list-item {
  &:last-child {
    border-bottom: none;
  }
}
