.products-page {
  height: calc(100vh - var(--header-height));

  .table-wrapper {
    min-height: 210px;
    max-height: calc(100vh - 22rem);

    tbody > tr {
      height: 69px;
    }
  }
}

.submit-ticket:hover {
  background-color: transparent !important;
}
