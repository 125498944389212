.option-panel {
  min-width: 18rem;
}

.open {
  background: #f3950033 !important;
}

.selected-label {
  color: #f39500 !important;
}
