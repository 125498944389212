.inplace-edit :global(.p-inplace-display) {
  display: contents;
}

.inplace-edit-display-container {
  word-break: break-all;
}

.inplace-edit-display-container:hover {
  background: var(--inplace-edit-display-container-hover-background-color);
  word-break: break-all;
}

.input-field-button-group {
  height: 100%;
  :global(.p-button.p-button-icon-only) {
    padding: 2px 4px 2px 4px;
    width: auto;
    height: 100%;
    aspect-ratio: 1;
  }
}
