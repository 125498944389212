.spinner {
  height: 100%;
  width: 100%;
  margin: 0;

  & svg > circle {
    stroke: var(--primary-color);
    stroke-width: 3;
    animation: spinner-dash 1.5s ease-in-out infinite;

    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
