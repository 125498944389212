.mobile-header-link {
  background-color: transparent;

  .inactive-icon {
    color: #3b4558;
  }

  .active-icon {
    color: var(--primary-color);
  }

  .dark-label {
    color: var(--body-color);
  }
}
