.list-action-button {
  color: var(--body-color) !important;
  font-weight: normal;
}

.list-action-button:hover {
  background-color: var(--custom-orange-color) !important;
}

.list-action-button:hover i {
  background-color: var(--primary-color) !important;
}

.user-avatar {
  background-color: var(--custom-orange-color);
  color: var(--primary-color);
  border-radius: 50% !important;  
}
